// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  return (
    <header className="App-header">
      <div className="nav-container">
        <nav className="nav">
          <Link to="/" className="nav-button">Home</Link>
          <Link to="/wwu" className="nav-button">Work With Us</Link>
          <Link to="/AboutUs" className="nav-button">About Us</Link>
        </nav>
      </div>
      <img src="/Franky_Logo.jpg" alt="Franky Logo" className="App-logo" />
      <h1>Franky</h1>
      <p>The App That Simplifies Store-Customer Interaction</p>
    </header>
  );
}

export default Header;
