// src/pages/AboutUs.js
import React from 'react';
import './AboutUs.css';

function AboutUs() {
  return (
    <div className="about-us-page">
      <div className="about-us-section">
        <img src="CEO.jpg" alt="This is our CEO" className="about-us-image" />
        <div className="about-us-text">
          <h2 className='about-us-heading'>Kristofher Muñoz - <strong>CEO</strong></h2>
          <p className='ceo-text'>I’m an Electrical Engineer from Universidad de Chile with a background as a Senior Data Scientist at Cornershop and Uber, where I led end-to-end development of time estimation models. My passion lies in e-commerce, blending technical expertise with a love for sale hunting and fast-paced idea development—often showcased at hackathons. Beyond work, I thrive on competition, whether in swimming or contemporary dance, pushing myself to excel in every arena. As CEO, I bring strategic leadership, agile execution, and a drive to innovate, building solutions that transform markets and deliver tangible value.</p>
        </div>
      </div>

      <div className="about-us-section">
        <img src="CTO.png" alt="This is our CTO" className="about-us-image" />
        <div className="about-us-text">
          <h2 className='about-us-heading'>Gaspar Pizarro - <strong>CTO</strong></h2>
          <p>This is our amazing CTO</p>
        </div>
      </div>

      <div className="about-us-section">
        <img src="PM.png" alt="This is me" className="about-us-image" />
        <div className="about-us-text">
          <h2 className='about-us-heading'>Jaime Cerda - <strong>Product Manager</strong></h2>
          <p>This is our amazing product manager</p>
        </div>
      </div>

    </div>
  );
}

export default AboutUs;
